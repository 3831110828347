<template>
  <header class="joe_header">
    <div class="joe_header__above">
      <div class="joe_container">

        <a :title="'小淘汰的私生活'" class="joe_header__above-logo" href="https://blog.zh52.cn/">
          <img class=" ls-is-cached lazyloaded" src="https://jsmao.cn/wp-content/uploads/2022/09/技术-LOGO图标-80x80-1.png"
               data-src="https://s1.ax1x.com/2023/05/06/p9a2M7T.png" alt="小淘汰的私生活">
        </a>
        <nav class="joe_header__above-nav">
          <a class="item active" href="https://blog.zh52.cn/" title="首页">首页</a>
          <a class="item " href="https://blog.zh52.cn/moyu.html" title="摸鱼日历">摸鱼日历</a>
          <a class="item " href="https://blog.zh52.cn/links.html" title="友链">友链</a>
          <a class="item " href="https://blog.zh52.cn/start-page.html" title="关于">关于</a>
<!--          <div class="joe_dropdown" trigger="hover" placement="60px">-->
<!--            <div class="joe_dropdown__link">-->
<!--              <a href="#" rel="nofollow">推荐</a>-->
<!--              <svg class="joe_dropdown__link-icon" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="14" height="14">-->
<!--                <path d="M561.873 725.165c-11.262 11.262-26.545 21.72-41.025 18.502-14.479 2.413-28.154-8.849-39.415-18.502L133.129 375.252c-17.697-17.696-17.697-46.655 0-64.352s46.655-17.696 64.351 0l324.173 333.021 324.977-333.02c17.696-17.697 46.655-17.697 64.351 0s17.697 46.655 0 64.351L561.873 725.165z" fill="var(&#45;&#45;main)"></path>-->
<!--              </svg>-->
<!--            </div>-->
<!--            <nav class="joe_dropdown__menu" style="top: 60px;">-->
<!--              <a href="http://hao.aissk.cn/index.php?k=t%1E%28g%CA%E9U%12%5D%97%D8%2A%AA%D9b%AD" target="_blank" rel="noopener noreferrer nofollow">精品流量卡</a>-->
<!--            </nav>-->
<!--          </div>-->
        </nav>
        <form class="joe_header__above-search" method="post" action="https://blog.zh52.cn/">
          <input maxlength="16" autocomplete="off" placeholder="请输入关键字..." name="s" value="" class="input" type="text">
          <button type="submit" class="submit">Search</button>
          <span class="icon"></span>
        </form>
        <svg class="joe_header__above-searchicon" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="20" height="20">
          <path d="M1008.19 932.031L771.72 695.56a431.153 431.153 0 1 0-76.158 76.158l236.408 236.472a53.758 53.758 0 0 0 76.158 0 53.758 53.758 0 0 0 0-76.158zM107.807 431.185a323.637 323.637 0 0 1 323.316-323.381 323.7 323.7 0 0 1 323.381 323.38 323.637 323.637 0 0 1-323.38 323.317 323.637 323.637 0 0 1-323.317-323.316z"></path>
        </svg>
      </div>
    </div>

    <div class="joe_header__below">
      <div class="joe_container">
        <nav class="joe_header__below-class">
          <a class="item " href="https://blog.zh52.cn/category/note/" title="个人笔记">个人笔记</a>
          <div class="joe_dropdown" trigger="hover">
            <div class="joe_dropdown__link">
              <a class="item " href="https://blog.zh52.cn/category/look/" title="值得一看">值得一看</a>
              <svg class="joe_dropdown__link-icon" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="13" height="13">
                <path d="M561.873 725.165c-11.262 11.262-26.545 21.72-41.025 18.502-14.479 2.413-28.154-8.849-39.415-18.502L133.129 375.252c-17.697-17.696-17.697-46.655 0-64.352s46.655-17.696 64.351 0l324.173 333.021 324.977-333.02c17.696-17.697 46.655-17.697 64.351 0s17.697 46.655 0 64.351L561.873 725.165z" fill="var(--minor)"></path>
              </svg>
            </div>
            <nav class="joe_dropdown__menu" style="top: 45px;">
              <a class="" href="https://blog.zh52.cn/category/Some/" title="一些文章">一些文章</a>
              <a class="" href="https://blog.zh52.cn/category/Course/" title="教程分享">教程分享</a>
            </nav>
          </div>
          <a class="item " href="https://blog.zh52.cn/category/software/" title="软件分享">软件分享</a>
        </nav>
        <div class="joe_header__below-sign">
          <div class="item">
            <svg class="icon" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="15" height="15">
              <path d="M710.698 299a213.572 213.572 0 1 0-213.572 213.954A213.572 213.572 0 0 0 710.698 299zm85.429 0a299.382 299.382 0 1 1-299-299 299 299 0 0 1 299 299z"></path>
              <path d="M114.223 1024a46.91 46.91 0 0 1-46.91-46.91 465.281 465.281 0 0 1 468.332-460.704 475.197 475.197 0 0 1 228.827 58.35 46.91 46.91 0 1 1-45.384 82.378 381.378 381.378 0 0 0-183.443-46.909 371.08 371.08 0 0 0-374.131 366.886A47.29 47.29 0 0 1 114.223 1024zM944.483 755.129a38.138 38.138 0 0 0-58.733 0l-146.449 152.55-92.675-91.53a38.138 38.138 0 0 0-58.732 0 43.858 43.858 0 0 0 0 61.402l117.083 122.422a14.492 14.492 0 0 0 8.39 4.577c4.196 0 4.196 4.195 8.39 4.195h32.037c4.195 0 4.195-4.195 8.39-4.195s4.195-4.577 8.39-4.577L946.39 816.15a48.054 48.054 0 0 0-1.906-61.02z"></path>
              <path d="M763.328 776.104L730.53 744.45a79.708 79.708 0 0 0 32.798 31.654"></path>
            </svg>
            <a href="https://blog.zh52.cn/admin/login.php" target="_blank" rel="noopener noreferrer nofollow">登录</a>
          </div>
        </div>
      </div>
    </div>



    <div class="joe_header__mask"></div>
  </header>
</template>

<script>
export default {
  name: "header"
}
</script>

<style scoped>

</style>
